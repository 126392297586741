
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuDesktop',

  mixins: [
    megaMenuMixin,
  ],

  props: {
    isNavbarOpen: {
      type: Boolean,
      required: true,
    },

    isHomepage: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    navbarId: null,
  }),

  methods: {
    onMenuItemClicked (node) {
      if (this.isHomepage) { return }

      this.toggleNavbar(node)
    },

    toggleNavbar (node) {
      this.navbarId = node.nodeId
      this.$emit('navbar-id', node.nodeId)
      this.$emit('toggle-navbar', !this.isNavbarOpen ? node.hasChildren : false)
    },
  },
}
