
import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'
import storeCode from '~/mixins/storeCode'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'TheMegaMenu',
  mixins: [
    storeCode,
  ],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      navbarId: 0,
    }
  },

  computed: {
    ...mapGettersConfig({
      categories: configTypes.GET_CATEGORIES,
      categoriesInMenu: configTypes.GET_CATEGORIES_IN_MENU,
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),

    isHomepage () {
      let path = this.$route.path

      if (this.$i18n.locale !== this.$i18n.defaultLocale) {
        const regex = new RegExp(`/${this.$i18n.locale}[^a-zA-Z0-9]?`)

        path = path.replace(regex, '/')
      }

      return path === '/' && this.isMedistoreStore
    },
  },

  watch: {
    isOpen (open) {
      const body = document.querySelector('body')

      open
        ? body.style.overflow = this.$css.breakpoints.smAndDown ? 'hidden' : null
        : body.style.overflow = null
    },
  },

  methods: {
    toggleNav (open) {
      this.$emit('toggle', open)
    },

    onClickOutside (e) {
      const btnClicked = !!e.target.closest('.megamenu-hamburger')

      if (!btnClicked) {
        this.toggleNav(false)
      }
    },

    setNavbarId (id) {
      this.navbarId = id
    },
  },
}
